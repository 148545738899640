import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ce51d46"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.opcoes, (opcao, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: 'opt-' + opcao.valor,
            name: "select",
            value: opcao.valor,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event))
          }, null, 8, _hoisted_1), [
            [_vModelRadio, _ctx.computedValor]
          ]),
          _createElementVNode("label", {
            for: 'opt-' + opcao.valor
          }, [
            _createElementVNode("h2", null, _toDisplayString(opcao.Titulo), 1),
            ((opcao.descricao))
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(opcao.descricao), 1))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2)
        ]))
      }), 128))
    ])
  ]))
}