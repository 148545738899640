
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IFormaEntrega, IFormaEntregaArea } from '@/models/Entidades/IFormaEntrega';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Icone from '@/core/components/UI/Icone.vue';
import ServicoFormaEntrega from '@/servicos/ServicoFormaEntrega';
import { IResposta } from '@/core/models/IResposta';
import { ETipoFormaEntrega } from '@/models/Enumeradores/ETipoFormaEntrega';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import CaixaSelecao from '@/core/components/UI/CaixaSelecao.vue';
import { ICaixaSelecao } from '@/core/models/Tela/ICaixaSelecao';
import { EConceitoValorFrete } from '@/models/Enumeradores/EConceitoValorFrete';

export default defineComponent({
  name: 'FormaEntregaCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    Icone,
    CampoCep,
    CampoNumerico,
    CaixaSelecao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota, apresentarMensagemAlerta,
    } = useAppBase();
    const servicoFormaEntrega = new ServicoFormaEntrega();
    let arquivoUpload = new FormData();

    const state = reactive({
      formaEntrega: {} as IFormaEntrega,
      opcoesCaixaSelacao: [] as ICaixaSelecao[],
      apresentarImportacaoTabela: false,
      listaArquivos: [],
    });

    async function obterFormaEntrega(codigo:number) {
      appBase.carregando = true;
      state.formaEntrega = await servicoFormaEntrega.obter(codigo);
      appBase.carregando = false;
    }

    function novaAreaEntrega() {
      const formaEntregaArea:IFormaEntregaArea = {} as IFormaEntregaArea;
      formaEntregaArea.codigo = 0;
      formaEntregaArea.codigoFormaEntrega = 0;
      formaEntregaArea.regiaoEstadoCidade = '';
      formaEntregaArea.cepInicial = '';
      formaEntregaArea.cepFinal = '';
      formaEntregaArea.pesoMinimo = 0;
      formaEntregaArea.pesoMaximo = 0;
      formaEntregaArea.prazoEntrega = 0;
      formaEntregaArea.valorFrete = 0;
      formaEntregaArea.removida = false;
      formaEntregaArea.alterada = false;
      state.formaEntrega.areas.push(formaEntregaArea);
    }

    function areaEntregaAlterada(index:number) {
      state.formaEntrega.areas[index].alterada = true;
    }

    function removerAreaEntrega(index:number) {
      if (UtilitarioGeral.validaCodigo(state.formaEntrega.areas[index].codigo)) {
        state.formaEntrega.areas[index].removida = true;
      } else {
        state.formaEntrega.areas.splice(index, 1);
      }
    }

    function montaCaixaSelecao() {
      state.opcoesCaixaSelacao = [
        { valor: ETipoFormaEntrega.RetiradaLocal, Titulo: 'Retirada Local', descricao: 'Seus clientes podem retirar os produtos na sua empresa/expedição, defina as regras e horários nas intruções.' },
        { valor: ETipoFormaEntrega.EntregaPropria, Titulo: 'Entrega Própria', descricao: 'Você efetua as entregas de seus produtos, escolha essa opção e configure as áreas de entrega.' },
        { valor: ETipoFormaEntrega.Transportadora, Titulo: 'Transportadora', descricao: 'Configure quantas Transportadoras quiser e aproveite o recurso para importar as tabelas.' },
        { valor: ETipoFormaEntrega.FRENET, Titulo: 'FRENET', descricao: 'Utilize a integração com a FRENET e tenha mais de 500 transportadoras disponíveis para suas entregas.' },
      ];
    }

    function limparDados() {
      state.formaEntrega = {} as IFormaEntrega;
      state.formaEntrega.codigo = 0;
      state.formaEntrega.tipo = ETipoFormaEntrega.EntregaPropria;
      state.formaEntrega.conceitoValorFrete = EConceitoValorFrete.ValorFixo;
      state.formaEntrega.status = true;
      state.formaEntrega.areas = [];
    }

    onBeforeMount(async () => {
      montaCaixaSelecao();
      limparDados();
      const codigoFormaEntrega = obterCodigoRota();
      if (codigoFormaEntrega > 0) {
        await obterFormaEntrega(codigoFormaEntrega);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Forma de Entrega.');

      if (state.formaEntrega.tipo === ETipoFormaEntrega.FRENET && UtilitarioGeral.validaLista(state.formaEntrega.areas)) {
        for (let index = 0; index < state.formaEntrega.areas.length; index += 1) {
          state.formaEntrega.areas[index].removida = true;
        }
      }
      if (state.formaEntrega.codigo === 0) {
        appBase.resposta = await servicoFormaEntrega.incluir(state.formaEntrega);
      } else {
        appBase.resposta = await servicoFormaEntrega.atualizar(state.formaEntrega);
      }

      apresentarRespostaRedirecionamento('FormasEntregas');
    }

    function removerArquivo(file:never) {
      const index = state.listaArquivos.indexOf(file);
      const newFileList = state.listaArquivos.slice();
      newFileList.splice(index, 1);
      state.listaArquivos = newFileList;
    }

    async function importarArquivo() {
      if (!UtilitarioGeral.objetoValido(state.listaArquivos)) {
        apresentarMensagemAlerta('É necessário selecionar um arquivo para realizar a importação!');
        return;
      }

      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Forma de Entrega.');

      if (state.formaEntrega.tipo === ETipoFormaEntrega.FRENET && UtilitarioGeral.validaLista(state.formaEntrega.areas)) {
        for (let index = 0; index < state.formaEntrega.areas.length; index += 1) {
          state.formaEntrega.areas[index].removida = true;
        }
      }

      appBase.resposta = await servicoFormaEntrega.importarTabela(state.formaEntrega.codigo, arquivoUpload);
      apresentarRespostaRedirecionamento('FormasEntregas');
    }

    function prepararArquivoUpload(arquivo:any) {
      arquivoUpload = new FormData();
      arquivoUpload.append('arquivo', arquivo);
    }
    return {
      appBase,
      state,
      salvar,
      ETipoFormaEntrega,
      EConceitoValorFrete,
      novaAreaEntrega,
      removerAreaEntrega,
      areaEntregaAlterada,
      importarArquivo,
      removerArquivo,
      prepararArquivoUpload,
    };
  },
});
