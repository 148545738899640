
import {
  computed, defineComponent,
} from 'vue';
import { ICaixaSelecao } from '@/core/models/Tela/ICaixaSelecao';

export default defineComponent({
  name: 'CaixaSelecao',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    opcoes: {
      type: Array as () => ICaixaSelecao[],
      default: [] as ICaixaSelecao[],
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        emit('update:valor', valor);
        emit('change', valor);
      },
    });

    return {
      props,
      computedValor,
    };
  },
});
