import ApiCommerce from '@/core/apis/ApiCommerce';
import { IResposta } from '@/core/models/IResposta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRepostaConsulta } from '@/core/models/IRepostaConsulta';
import { IFormaEntregaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IFormaEntregaParametrosConsulta';
import { IFormaEntrega } from '@/models/Entidades/IFormaEntrega';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
import { IDTOFormaEntrega } from '@/models/DTO/IDTOFormaEntrega';
/**
 * Serviço de Forma Entrega
 */

class ServicoFormaEntrega implements IServicoBase<IFormaEntrega> {
  endPoint = 'formas-entregas';

  apiCommerce = new ApiCommerce();

  public async incluir(formaEntrega: IFormaEntrega): Promise<IResposta> {
    const result: any = await this.apiCommerce.post(`${this.endPoint}`, formaEntrega);
    return result.data;
  }

  public async atualizar(formaEntrega: IFormaEntrega): Promise<IResposta> {
    const result: any = await this.apiCommerce.put(`${this.endPoint}/${formaEntrega.codigo}`, formaEntrega);
    return result.data;
  }

  public async obter(codigo: number): Promise<IFormaEntrega> {
    const result: any = await this.apiCommerce.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterFormaEntregas(parametrosConsulta: IFormaEntregaParametrosConsulta): Promise<IRepostaConsulta<IDTOFormaEntrega>> {
    let parametrosAdicionais = '';

    if (parametrosConsulta.valorPesquisa !== undefined) {
      parametrosAdicionais += `?ValorPesquisa=${parametrosConsulta.valorPesquisa}`;
    }

    if (parametrosConsulta.status !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Status=${parametrosConsulta.status}`;
    }

    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigos !== undefined) {
      parametrosConsulta.codigos.forEach((codigo) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigo}`;
      });
    }
    const result: any = await this.apiCommerce.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaRapida(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
    const parametros = this.apiCommerce.obterParametrosBuscaRapida(parametrosConsultaRapida);
    const result: any = await this.apiCommerce.get(`${this.endPoint}/busca-rapida${parametros}`);
    return result.data;
  }

  public async deletar(codigo: number): Promise<IResposta> {
    const result: any = await this.apiCommerce.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async importarTabela(codigoForma:number, arquivo: any): Promise<IResposta> {
    const result: any = await this.apiCommerce.upload(`${this.endPoint}/${codigoForma}/importar-tabela`, arquivo);
    return result.data;
  }
}

export default ServicoFormaEntrega;
